import { computed, Injectable, signal } from '@angular/core'
import { ICurrency, usdCurrency, CurrencyCode } from 'src/app/core/interfaces/currency.interface'
import { IConfig } from '../interfaces/config.interface'
import { ImageSizes } from '../interfaces/media-library.interface'

/**
 * This service is used to store global data that needs to be shared between components and services.
 * The main reason for this is to abstract the data into a service that has no dependencies.
 * This reduces configuration for tests with regards to dependencies.
 * As a general rule, the values stored in this service should be set from outside the service to keep dependencies to a minimum.
 */
@Injectable({
  providedIn: 'root'
})
export class GlobalDataService { // TODO rename to StateService
  config = signal<IConfig>({} as IConfig)
  loadedCurrencies = signal<ICurrency[]>([])
  defaultCurrency = signal<CurrencyCode | null>(null)
  selectedCurrency = signal<ICurrency>(usdCurrency)
  unitOfDistance = signal<'miles' | 'kilometers'>('miles')
  defaultImage = computed<string>(() => {
    return this.config().general?.data?.default_img_data?.urls[ImageSizes.DefaultProductImage]
      ? this.config().general?.data?.default_img_data?.urls[ImageSizes.DefaultProductImage]
      : 'https://catalog-assets.rousesales.com/_default/content/no-photo2.png'
  })
}
